import Filter from '@/model/shared/Filter';
import { CAMPAIGN_STATUS } from '@/model/modules/socialAudience/campaign/CampaignStatus';
import { CAMPAIGN_TYPES } from '@/model/modules/socialAudience/campaign/CampaignTypes';
import { getAdvertiserById } from '@/services/modules/socialAudience/advertiser';
import { getCategoryById } from '@/services/modules/Core/category';
import { ROLES } from '@/model/shared/roles';

const costOptions = [
  { id: 'gte', name: 'Greater', symbol: '>=' },
  { id: 'lte', name: 'Lower', symbol: '<=' },
];

const getCostOptionByText =
  (filterOptions = []) =>
  text =>
    new Promise((resolve, reject) => {
      const error = new Error('Invalid cost option.');
      const parse = /^\[(.+)\](.+)$/.exec(text);
      if (parse) {
        const [value, id, input] = parse;
        const option = filterOptions.find(opt => opt.id === id);
        option ? resolve({ id, value, input, text: `${option.symbol} ${input}` }) : reject(error);
      } else {
        reject(error);
      }
    });

export const FILTERS_KEYS = {
  NAME: 'NAME',
  CAMPAIGN_STATUS_SIMPLE: 'CAMPAIGN_STATUS_SIMPLE',
  CAMPAIGN_NAME: 'CAMPAIGN_NAME',
  STATUS: 'STATUS',
  ADVERTISER_ID: 'ADVERTISER_ID',
  CAMPAIGN_TYPE: 'CAMPAIGN_TYPE',
  ADVERTISER: 'ADVERTISER',
  CATEGORY: 'CATEGORY',
  SUB_CATEGORY: 'SUB_CATEGORY',
  TOTAL_COST: 'TOTAL_COST',
  LAST30_DAYS_COST: 'LAST30_DAYS_COST',
};

export default {
  NAME: new Filter(FILTERS_KEYS.NAME, 'Name', 'name'),
  CAMPAIGN_NAME: new Filter(FILTERS_KEYS.CAMPAIGN_NAME, 'Name', 'campaign.name').setBreakdown(
    'advertiser',
    'campaign',
    'campaignGroup'
  ),

  CAMPAIGN_STATUS: new Filter(FILTERS_KEYS.STATUS, 'Status', 'status').setOptions(CAMPAIGN_STATUS),
  ANALYTICS_STATUS: new Filter(FILTERS_KEYS.STATUS, 'Status', 'campaign.status')
    .setOptions(CAMPAIGN_STATUS)
    .setBreakdown('advertiser', 'campaign', 'campaignGroup'),

  ADVERTISER: new Filter(FILTERS_KEYS.ADVERTISER, 'Client', 'advertiser.id').getItemBy(getAdvertiserById).asMultiple(),
  ADVERTISER_ID: new Filter(FILTERS_KEYS.ADVERTISER_ID, 'Client', 'advertiser.id')
    .asMultiple()
    .getItemBy(getAdvertiserById)
    .setAllowedRoles([ROLES.ADMIN.id, ROLES.AD_OPS.id])
    .setBreakdown('campaign', 'campaignGroup'),

  CAMPAIGN_TYPE: new Filter(FILTERS_KEYS.CAMPAIGN_TYPE, 'Type', 'type')
    .setOptions(CAMPAIGN_TYPES)
    .setBreakdown('campaign', 'campaignGroup'),
  ANALYTICS_TYPE: new Filter(FILTERS_KEYS.CAMPAIGN_TYPE, 'Type', 'campaign.type')
    .setOptions(CAMPAIGN_TYPES)
    .setBreakdown('campaign', 'campaignGroup'),

  CATEGORY: new Filter(FILTERS_KEYS.CATEGORY, 'Category', 'category').getItemBy(getCategoryById).asMultiple(),
  SUB_CATEGORY: new Filter(FILTERS_KEYS.SUB_CATEGORY, 'Sub Category', 'subCategory')
    .getItemBy(getCategoryById)
    .asMultiple(),
  TOTAL_COST: new Filter(FILTERS_KEYS.TOTAL_COST, 'Total cost', 'totalCost', 'text', 'value')
    .setOptions(costOptions)
    .getItemBy(getCostOptionByText(costOptions)),
  LAST30_DAYS_COST: new Filter(FILTERS_KEYS.LAST30_DAYS_COST, 'Last 30 days cost', 'last30DaysCost', 'text', 'value')
    .setOptions(costOptions)
    .getItemBy(getCostOptionByText(costOptions)),
};
